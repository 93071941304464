a {
  color: inherit;
  text-decoration: none;
}

::-webkit-scrollbar {
  width: 16px;
}
::-webkit-scrollbar-thumb {
  border: 4px solid #00000000;
  border-radius: 8px;
  background: #ccc;
  background-clip: padding-box;
}
::-webkit-scrollbar-thumb:hover {
  background: #bbb;
  background-clip: padding-box;
}
::-webkit-scrollbar-thumb:active {
  background: #aaa;
  background-clip: padding-box;
}

#login {
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
}

.MuiAppBar-root-17 {
  position: absolute;
  z-index: -1;
}

.MuiCollapse-container-170 {
  width: 100%;
}

.MuiStepIcon-active {
  border-radius: 12px;
  box-shadow: 0 0 0 0 #f44232;
  animation: pulse 1s infinite;
}

[id*="react-select"] {
  opacity: 1 !important;
}

/* Image Gallery Fixes */

.image-gallery-svg {
  max-width: 40px !important;
  max-height: 80px !important;
  min-width: 24px !important;
  min-height: 24px !important;
}
.image-gallery-image, .image-gallery-thumbnail-image, .ril-image-current {
  background: #000000;
}

/* Toastify */

.Toastify__toast--info {
  background: #2080ff !important;
}
.Toastify__toast--error {
  background: #ff2050 !important;
}
.Toastify__toast--warning {
  background: #ffa040 !important;
}
.Toastify__toast--success {
  background: #20c820 !important;
}

@keyframes pulse {
  0% {
    filter: brightness(80%);
  }
  45% {
    filter: brightness(100%);
  }
  80% {
    box-shadow: 0 0 0 8px #00000000;
  }
  100% {
    filter: brightness(80%);
    box-shadow: 0 0 0 0 #00000000;
  }
}
.react-contextmenu {
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 4px;
    color: #323232;
    font-size: 16px;
    min-width: 160px;
    outline: none;
    opacity: 0;
    padding: 8px 0;
    pointer-events: none;
    text-align: left;
    transition: opacity 250ms ease !important;
}

.react-contextmenu.react-contextmenu--visible {
    opacity: 1;
    pointer-events: auto;
    z-index: 9999;
}

.react-contextmenu-item {
    background: 0 0;
    border: 0;
    color: #323232;
	cursor: pointer;
    font-weight: 400;
    line-height: 1.25;
    padding: 12px 16px;
    text-align: inherit;
    white-space: nowrap;
    transition: 0.1s;
    outline: none;
}

.react-contextmenu-item.react-contextmenu-item--active,
.react-contextmenu-item.react-contextmenu-item--selected {
    color: #fff;
    background-color: #f44232;
    border-color: #f44232;
    text-decoration: none;
}

.react-contextmenu-item.react-contextmenu-item--disabled,
.react-contextmenu-item.react-contextmenu-item--disabled:hover {
    background-color: transparent;
    border-color: rgba(0,0,0,.15);
    color: #a8a8a8;
}

.react-contextmenu-item--divider {
    border-bottom: 1px solid rgba(0,0,0,.15);
    cursor: inherit;
    margin-bottom: 3px;
    padding: 2px 0;
}
.react-contextmenu-item--divider:hover {
    background-color: transparent;
    border-color: rgba(0,0,0,.15);
}

.react-contextmenu-item.react-contextmenu-submenu {
	padding: 0;
}

.react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item {
}

.react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item:after {
    content: "\25B6";
    display: inline-block;
    position: absolute;
    right: 7px;
}

.example-multiple-targets::after {
    content: attr(data-count);
    display: block;
}
