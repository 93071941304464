.react-contextmenu {
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 4px;
    color: #323232;
    font-size: 16px;
    min-width: 160px;
    outline: none;
    opacity: 0;
    padding: 8px 0;
    pointer-events: none;
    text-align: left;
    transition: opacity 250ms ease !important;
}

.react-contextmenu.react-contextmenu--visible {
    opacity: 1;
    pointer-events: auto;
    z-index: 9999;
}

.react-contextmenu-item {
    background: 0 0;
    border: 0;
    color: #323232;
	cursor: pointer;
    font-weight: 400;
    line-height: 1.25;
    padding: 12px 16px;
    text-align: inherit;
    white-space: nowrap;
    transition: 0.1s;
    outline: none;
}

.react-contextmenu-item.react-contextmenu-item--active,
.react-contextmenu-item.react-contextmenu-item--selected {
    color: #fff;
    background-color: #f44232;
    border-color: #f44232;
    text-decoration: none;
}

.react-contextmenu-item.react-contextmenu-item--disabled,
.react-contextmenu-item.react-contextmenu-item--disabled:hover {
    background-color: transparent;
    border-color: rgba(0,0,0,.15);
    color: #a8a8a8;
}

.react-contextmenu-item--divider {
    border-bottom: 1px solid rgba(0,0,0,.15);
    cursor: inherit;
    margin-bottom: 3px;
    padding: 2px 0;
}
.react-contextmenu-item--divider:hover {
    background-color: transparent;
    border-color: rgba(0,0,0,.15);
}

.react-contextmenu-item.react-contextmenu-submenu {
	padding: 0;
}

.react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item {
}

.react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item:after {
    content: "▶";
    display: inline-block;
    position: absolute;
    right: 7px;
}

.example-multiple-targets::after {
    content: attr(data-count);
    display: block;
}